

// App.js
import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import SocialLinks from './components/SocialLinks';
import Contact from './components/Contact';
import CustomCursor from './components/CustomCursor';



function App() {
  return (
    <div className="bg-bg-dark text-text-primary min-h-screen p-10 lg:p-20 ">
      <CustomCursor />
      <div className="max-w-6xl mx-auto ">
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-2/5 lg:fixed ">
            <Header />
            <SocialLinks />
          </div>
          <div className="lg:w-3/5 lg:ml-auto lg:pl-20 ">
            <About />
            <Experience />
            <Projects />
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;